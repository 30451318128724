import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";

export const frontmatter = {
  title: "Día 42",
  week: "Semana 6",
  month: "abr",
  day: "24",
  monthNumber: "04",
  date: "2020-04-24",
  path: "/cronologia/semana-06#dia-24-abr",
};
const Day42 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={"2020-04-24"} />
        <ModLethalityEu fecha={"2020-04-24"} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 6.740 nuevos positivos y 367 personas fallecidas.
          Permanecen hospitalizadas 78.545 personas, 456 más que el día
          anterior, y 3.105 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.780, lo que supone un aumento de 75 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 42,0 %.
        </ModText>
        <ModText>
          Desde el pasado 10 de marzo, el Gobierno ha distribuido entre las
          CC.AA. más de 90,9 millones de unidades de material sanitario y 3
          millones de test rápidos.
        </ModText>
        <ModGraph
          name="24-04-evolucion-confirmados-fallecidos"
          alt="Evolución semanal intradía España de Confirmados y fallecidos por Covid-19"
        />
        <ModText>
          Los datos de hoy arrojan un fuerte repunte de los casos confirmados,
          posiblemente a consecuencia del mayor número de test realizados. El
          ministerio ha decidido eliminar de su reporte los resultados de los
          casos obtenidos mediante tests seriológicos, manteniendo
          exclusivamente los de PCR. En las gráficas y los datos se muestran la
          suma de todos los tests, independientemente de su naturaleza.
        </ModText>
        <ModGraph
          name="24-04-desfase-letalidad-es"
          alt="Evolución media intradía España de Confirmados y fallecidos por Covid-19"
        />
        <ModText>
          Una visión distinta de los mismos datos, en serie temporal completa,
          escalando número de fallecidos por la tasa de letalidad (10,2%).
        </ModText>
        <ModTwoCols
          src="/images/svg/24_abr_laboratorios.svg"
          alt="estudio"
          small={false}
        >
          El Instituto de Salud Carlos III (ISCII) ha{" "}
          <strong> 11 nuevos laboratorios</strong> de centros de investigación y
          universidades para hacer test PCR para el diagnóstico de COVID-19. Con
          estos, ya son 24 los centros habilitados, que pueden hacer más de
          5.000 pruebas diagnósticas al día.
        </ModTwoCols>
        <ModText>
          Asimismo, también llevará a cabo el{" "}
          <strong>
            “Estudio Nacional Epidemiológico de la infección por COVID-19 en
            España”
          </strong>
          , junto al Ministerio de Sanidad, y al que prestarán apoyo las
          diferentes CCAA.
        </ModText>
        <ModImage
          src="/images/svg/24-04-estudio-epidemia.svg"
          alt="estudio epidemia"
        />
        <ModText>
          La finalidad del estudio es el porcentaje de la población que ha
          desarrollado anticuerpos frente al nuevo coronavirus, se han
          seleccionado más de <strong>36.000 hogares españoles</strong> (lo que
          supone un mínimo de 60.000 personas) para que la muestra tenga
          participantes de todos los grupos de edad y localizaciones
          geográficas.
        </ModText>
        <ModText>
          Además, el estudio también pretende{" "}
          <strong>evaluar de forma dinámica la evolución de la epidemia</strong>
          , para lo cual se realizarán varias visitas a los participantes para
          determinar si desarrollan anticuerpos a lo largo del periodo de
          estudio.
        </ModText>
        <ModTwoCols
          src="/images/svg/24_abr_proteccion.svg"
          alt="mascarillas guantes y geles"
          small={false}
        >
          Por último, hoy{" "}
          <strong>han entrado en vigor los precios máximos</strong> de venta al
          público de mascarillas quirúrgicas, geles y soluciones
          hidroalcohólicas autorizados temporalmente por la Agencia Española de
          Medicamentos y Productos Sanitarios.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day42;
